import componentInitializer from "@/frontend/utils/componentInitializer"

componentInitializer(() => {
  const elements = document.getElementsByClassName("load-recaptcha")

  for (let i = 0; i < elements.length; i++) {
    const el = elements[i]
    if (el) {
      $(`#${el.id}`).one("show.bs.modal", function () {
        const scriptSrc = "https://www.google.com/recaptcha/api.js"
        const script = document.createElement("script")
        script.src = scriptSrc
        document.head.appendChild(script)
      })
    }
  }
})
